import React from "react"
import Link from "gatsby-link"
var moment = require('moment');

//function titleCheck(post) { if (post.frontmatter.excerpt.includes("Patch notes")) {titleLink = "Patch Notes"} else {titleLink = 'This Week at Not Bungie'}}

const twanbLink = ({ post }) => (

  <div className="banner-content">
    <Link to={"/" + post.slug.current}><h2>{post.title}</h2></Link>
    <p className="hand-written" >{moment(post.publishedAt).format('MM-DD-YYYY')} - {post.employee && <a href={'/staff/#' + post.employee.slug.current}>{post.employee.name}</a>}</p>
    <p>{post.excerpt}</p>
  </div>
)

export default twanbLink